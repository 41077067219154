import React, { ReactElement, useRef, useEffect, useState } from 'react'; 
import "./style.css"
import { useLocation } from '@reach/router';

export const CWScripts = (): ReactElement => {
 
  const location = useLocation();
 
  useEffect(()=>{

    if(  location.pathname !== '/'){
      /***
      **** code block to change all links to pricing section
      ***/

      function changeLinks(){
        let allLinks = document.getElementsByTagName("a")
  
        for (var i=0; i<allLinks.length; i++) {
            //console.log(typeof allLinks[i].getAttribute("href"), allLinks[i].getAttribute("href"));
            //console.log(allLinks[i].text, allLinks[i].text.toLowerCase().includes('get sketchwow'), allLinks[i].getAttribute("class"))
            if((allLinks[i].getAttribute("href").includes("checkout.sketchwow.com") || allLinks[i].getAttribute("href").includes("/pricing")) && !(allLinks[i].text).toLowerCase().includes('get started')) {
                allLinks[i].setAttribute("href",  "#sw-pricing");
                //allLinks[i].setAttribute("href", allLinks[i].getAttribute("href") + "#sw-pricing");
            }
        }
      }
      setTimeout(function(){  changeLinks(); }, 1000);

      
      /***
      **** end code block
      ***/
  }



    function addContent() {
      var orderLink = document.querySelector('#order-now-link').getAttribute('href');
      var stickyBarHTML = `
        <div class="cw_sw_1_sticky_bar">
          <div class="cw_sw_1_sticky_bar_container">
            <div class="cw_sw_1_flex_container">
              <div class="cw_sw_1_flex_content">
                <div class="cw_sw_1_mn_heading">Get SketchWow for a 1-Time Price</div>
                <div class="cw_sw_1_mn_content">ZERO MONTHLY / ANNUAL FEES FOREVER</div>
              </div>
              <div class="cw_sw_1_mn_cta">
                <a href="${orderLink}">TRY SKETCHWOW RISK-FREE</a>
              </div>
            </div>
          </div>
        </div>
      `;
      document.querySelector('main').insertAdjacentHTML('afterend', stickyBarHTML);
    }
  
    var observer = new MutationObserver(function(mutations, me) {
      if (document.querySelector('main')) {
       setTimeout(addContent, 1000);
        me.disconnect();
      }
    });
  
    observer.observe(document, {
      childList: true,
      subtree: true
    });
  
    window.addEventListener('scroll', function() {
      var stickyBar = document.querySelector('.cw_sw_1_sticky_bar');
      var isMobile = false;
      // var offsetHeight = document.getElementById('pricing').offsetHeight;
      // console.log(offsetHeight);
      if(typeof window !== 'undefined'){
        if(window.innerWidth <= 768)
          isMobile = true;
        else
          isMobile = false;
      }

      if (stickyBar) {
        if (window.scrollY >= 1000) {
          document.body.classList.add('scroll_active');
          stickyBar.classList.add('sticky_active');
        } else {
          document.body.classList.remove('scroll_active');
          stickyBar.classList.remove('sticky_active');
        }

        if (((window.innerHeight + window.scrollY) + (isMobile ? 3000 : 300)) >= document.body.scrollHeight) {
          document.body.classList.remove('scroll_active');
          stickyBar.classList.remove('sticky_active');
        } 
      }
    });


    const deal_qstr =  window.location.href.split('?')[1];
    let link = " /pricing/?swp=home";
    if(location.pathname == '/sale/' || location.pathname == '/sale')
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=sl"; 
    else if(location.pathname == '/save/' || location.pathname == '/save')
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=sv";
    else if(location.pathname == '/offer/' || location.pathname == '/offer')
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=of";
    else if(location.pathname == '/lifetime/' || location.pathname == '/lifetime')
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=lt";
    else if(location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal')
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=ltd";
    else if(location.pathname == '/deal/' || location.pathname == '/deal')
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=dl";
    else if((location.pathname == '/special/' || location.pathname == '/special') && localStorage.getItem('swcode'))
      link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=sp&code="+localStorage.getItem('swcode'); //link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=sp&coupon_code=BYTE10OFF&code="+localStorage.getItem('swcode');
    else if(location.pathname == '/promo/' || location.pathname == '/promo')
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=pr";
    else if(location.pathname == '/yt-deal/' || location.pathname == '/yt-deal')
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=yt";
 
    if(typeof deal_qstr !== 'undefined'){
      link = link + "&" + deal_qstr;
    }

 
        

    var CWTargetElement = document.querySelector(".e1rrpdhi14");
    CWTargetElement.insertAdjacentHTML('beforebegin', `<section class="cw-container-sketch css-1l5b2id e1rrpdhi12">
    <div class="css-5fl59n ekes0c03">
    <div class="cw-sketch-new-section" >
          <div class="cw-heading-sketch">
            <h2 class="css-4pv62i e1rrpdhi9">Competitors <small>vs</small> <span class="bg-color-addimg">SketchWow</span></h2>
          </div>
                <div class="cw-main-two-section">
                <div class="cw-bg1-section">
                    <div class="bg1heading css-4pv62i e1rrpdhi9">Typical Software</div>
                    <ul class="cw-list-sketech">
                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/Close.png" alt=""></span> <span
                                class="cw-text-sketch"></span>Overused "too-perfect-looking"
                            templates</li>
                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/Close.png" alt=""></span> <span
                                class="cw-text-sketch"></span>Difficult to learn & navigate</li>
                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/Close.png" alt=""></span> <span
                                class="cw-text-sketch"></span>Makes 2,000 types of designs (99%
                                you'll never use)</li>
                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/Close.png" alt=""></span> <span
                                class="cw-text-sketch"></span>Makes boring, forgettable designs</li>

                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/Close.png" alt=""></span> <span
                                class="cw-text-sketch"></span>Typical user experience</li>
                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/Close.png" alt=""></span> <span
                                class="cw-text-sketch"></span>Expensive monthly (recurring) fees</li>
                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/Close.png" alt=""></span> <span
                                class="cw-text-sketch"></span>Limited AI features & visuals</li>
                    </ul>
                </div>
                <div class="cw-sectionvs">Vs</div>
                <div class="cw-bg2-section">
                    <div class="bg2-img">
                        <img src="/cwfiles/sketch-logo.png" alt="">
                    </div>
                    <ul class="cw-list-sketech">
                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/check-light.png" alt=""></span> <span
                            class="cw-text-sketch"></span>Eye-catching templates unlike anything
                            you've seen</li>
                    <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/check-light.png" alt=""></span> <span
                            class="cw-text-sketch"></span>Simple to navigate and use</li>
                    <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/check-light.png" alt=""></span> <span
                            class="cw-text-sketch"></span>Designed for making clean, diagrams,
                            workflows and visuals</li>
                    <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/check-light.png" alt=""></span> <span
                            class="cw-text-sketch"></span>Creates unique, hand-drawn style visuals</li>

                    <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/check-light.png" alt=""></span> <span
                            class="cw-text-sketch"></span>"The most fun drag & drop diagram maker"</li>
                    <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/check-light.png" alt=""></span> <span
                            class="cw-text-sketch"></span>Only $49, one time</li>
                    <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/check-light.png" alt=""></span> <span
                            class="cw-text-sketch"></span>Many AI-powered options: mindmaps,
                            workflows, visual summaries, infographics</li>
                    </ul>
                </div>
            </div>
            <div class="cw-button-start">
                <a href="`+link+`" class="css-10gfo7v exn679h2">Get SketchWow</a>
            </div>
        </div>
    </div>
    </section>`);



    if (!document.getElementById("sw-pricing") && location.pathname !== '/') {
      document.querySelector(".e1lhtz337").insertAdjacentHTML('beforebegin', `
          <section id="sw-pricing" class="css-1g4kmyn e1pe3e9c4">
              <div class="cw-container-pricing">
                  <div class="cw-bg-color">
                      <div class="cw-wrapper">
                          <div class="cw-logo"><img src="/cwfiles/sketch-logo.png" alt="checkmark"></div>
                          <div class="cw-subscription">
                              <div class="cw-price-pricing">$39<sub>/mo</sub></div>
                              <h2 class="cw-sub">Subscription</h2>
                              <h5 class="cw-bill">(Billed Monthly)</h5>
                          </div>
                          <div class="cw-subscription2-pricing">
                              <div class="cw-price-pricing">$49</div>
                              <h2 class="cw-sub">Lifetime</h2>
                              <h5 class="cw-bill">(One-time Payment)</h5>
                          </div>
                      </div>
                      <ul class="cw-list-container">
                          <li>
                              <div class="text">Unlimited sketches</div>
                              <div class="check-button-light"><img src="/cwfiles/check-light.png" alt="checkmark"></div>
                              <div class="check-button-dark"><img src="/cwfiles/check-dark.png" alt="checkmark"></div>
                          </li>
                          <li>
                              <div class="text">AI-powered mindmaps, flows, visuals</div>
                              <div class="check-button-light"><img src="/cwfiles/check-light.png" alt="checkmark"></div>
                              <div class="check-button-dark"><img src="/cwfiles/check-dark.png" alt="checkmark"></div>
                          </li>
                          <li>
                              <div class="text">100+ templates</div>
                              <div class="check-button-light"><img src="/cwfiles/check-light.png" alt="checkmark"></div>
                              <div class="check-button-dark"><img src="/cwfiles/check-dark.png" alt="checkmark"></div>
                          </li>
                          <li>
                              <div class="text">350 elements, shapes, icons</div>
                              <div class="check-button-light"><img src="/cwfiles/check-light.png" alt="checkmark"></div>
                              <div class="check-button-dark"><img src="/cwfiles/check-dark.png" alt="checkmark"></div>
                          </li>
                          <li>
                              <div class="text">Standard color backgrounds</div>
                              <div class="check-button-light"><img src="/cwfiles/check-light.png" alt="checkmark"></div>
                              <div class="check-button-dark"><img src="/cwfiles/check-dark.png" alt="checkmark"></div>
                          </li>
                          <li>
                              <div class="text">30 fonts (+ import custom fonts)</div>
                              <div class="check-button-light"><img src="/cwfiles/check-light.png" alt="checkmark"></div>
                              <div class="check-button-dark"><img src="/cwfiles/check-dark.png" alt="checkmark"></div>
                          </li>
                          <li>
                              <div class="text">Resize canvas (Custom size)</div>
                              <div class="check-button-light"><img src="/cwfiles/check-light.png" alt="checkmark"></div>
                              <div class="check-button-dark"><img src="/cwfiles/check-dark.png" alt="checkmark"></div>
                          </li>
                          <li>
                              <div class="text">Import Images (Drag-and-drop)</div>
                              <div class="check-button-light"><img src="/cwfiles/check-light.png" alt="checkmark"></div>
                              <div class="check-button-dark"><img src="/cwfiles/check-dark.png" alt="checkmark"></div>
                          </li>
                          <li>
                              <div class="text">JPG, PNG, SVG, PDF, Animated GIF (Export)</div>
                              <div class="check-button-light"><img src="/cwfiles/check-light.png" alt="checkmark"></div>
                              <div class="check-button-dark"><img src="/cwfiles/check-dark.png" alt="checkmark"></div>
                          </li>
                          <li>
                              <div class="text">Presentation mode</div>
                              <div class="check-button-light"><img src="/cwfiles/check-light.png" alt="checkmark"></div>
                              <div class="check-button-dark"><img src="/cwfiles/check-dark.png" alt="checkmark"></div>
                          </li>
                          <li>
                              <div class="text">File backup &amp; restore</div>
                              <div class="check-button-light"><img src="/cwfiles/check-light.png" alt="checkmark"></div>
                              <div class="check-button-dark"><img src="/cwfiles/check-dark.png" alt="checkmark"></div>
                          </li>
                          <li>
                              <div class="text">Use on multiple computers</div>
                              <div class="check-button-light"><img src="/cwfiles/check-light.png" alt="checkmark"></div>
                              <div class="check-button-dark"><img src="/cwfiles/check-dark.png" alt="checkmark"></div>
                          </li>
                          <li>
                              <div class="text">Standard support</div>
                              <div class="check-button-light"><img src="/cwfiles/check-light.png" alt="checkmark"></div>
                              <div class="check-button-dark"><img src="/cwfiles/check-dark.png" alt="checkmark"></div>
                          </li>
                      </ul>
                      <div class="button-wrapper">
                          <div class="empty-box"></div>
                          <div class="startedbutton"><a
                                  href="https://checkout.sketchwow.com/?add-to-cart=816352&swp=home"
                                  class="new-pricing-link-sub"><button type="button">get started</button></a></div>
                          <div class="startedbutton active"><a
                                  href="https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=home"
                                  class="new-pricing-link"><button type="button">get started</button></a></div>
                      </div>
                  </div>
              </div>
          </section>
      `);
  }
  


  let links = document.getElementsByClassName("new-pricing-link");
  let linkssub = document.getElementsByClassName("new-pricing-link-sub");


  let linksubs = "https://checkout.sketchwow.com/?add-to-cart=816352";

  
  if(location.pathname == '/sale/' || location.pathname == '/sale'){
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=sl"; 
      linksubs = "https://checkout.sketchwow.com?add-to-cart=816352&cg2=1&swp=sl"; 
  }else if(location.pathname == '/save/' || location.pathname == '/save'){
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=sv";
      linksubs = "https://checkout.sketchwow.com?add-to-cart=816352&cg2=1&swp=sv"; 
  }else if(location.pathname == '/offer/' || location.pathname == '/offer'){
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=of";
      linksubs = "https://checkout.sketchwow.com?add-to-cart=816352&cg2=1&swp=of"; 
  }else if(location.pathname == '/lifetime/' || location.pathname == '/lifetime'){
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=lt";
      linksubs = "https://checkout.sketchwow.com?add-to-cart=816352&cg2=1&swp=lt"; 
  }else if(location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal'){
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=ltd";
      linksubs = "https://checkout.sketchwow.com?add-to-cart=816352&cg2=1&swp=ltd"; 
  }else if(location.pathname == '/deal/' || location.pathname == '/deal'){
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=dl";
      linksubs = "https://checkout.sketchwow.com?add-to-cart=816352&cg2=1&swp=dl"; 
  }else if((location.pathname == '/special/' || location.pathname == '/special') && localStorage.getItem('swcode')){
       link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=sp&code="+localStorage.getItem('swcode');
      linksubs = "https://checkout.sketchwow.com/?add-to-cart=816352&cg2=1&swp=sp " ;
  }else  if(location.pathname == '/promo/' || location.pathname == '/promo'){
      link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=pr";
      linksubs = "https://checkout.sketchwow.com?add-to-cart=816352&cg2=1&swp=pr";
  }else{
     link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=home";
     linksubs = "https://checkout.sketchwow.com/?add-to-cart=816352&swp=home";
  }
  //console.log(links, linkssub)

  if(typeof deal_qstr !== 'undefined' &&  links !== null){
    let i;
    for (i = 0; i < links.length; i++) {
      //console.log(links[i].href)
      links[i].href = link + '&'  + deal_qstr;
    }
  }else if(links !== null){
    let i;
    for (i = 0; i < links.length; i++) {
      //console.log(links[i].href)
      links[i].href = link;
    }
  }

  if(typeof deal_qstr !== 'undefined' &&  linkssub !== null){
      let i;
      for (i = 0; i < linkssub.length; i++) {
        linkssub[i].href = linksubs + '&'  + deal_qstr;
      }
    }else if(linkssub !== null){
      let i;
      for (i = 0; i < linkssub.length; i++) {
        linkssub[i].href = linksubs;
      }
    }





}, []);

 

  return (
    <>  </>
            
  );

  
};
